<template>
  <b-sidebar
    id="add-new-desordre-sidebar"
    :visible="isAddNewDesordreSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => {$emit('update:is-add-new-desordre-sidebar-active', val);showDesordre(val)}"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0" v-if="!desordreData.id">
          Ajout d'un nouveau défaut
        </h5>

        <h5 class="mb-0" v-if="desordreData.id">
          Revue d'un défaut
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <div class="ml-2 mt-2" v-if="desordreData.id">
        <b-row class="mb-1">Type : {{ desordreData.name }}</b-row>
        <b-row class="mb-1">Dernière description : <br/>{{ desordreData.description }}</b-row>
        <b-row class="mb-1 justify-content-center ">Dernière photo :&nbsp;&nbsp;&nbsp;&nbsp; <br/><img :src="desordreData.image" height="200px" width="300px" /></b-row>
        <b-row class="d-flex justify-content-center align-items-center text-center" md="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          type="button"
          @click="stableDefaut"
        >
          Déclarer comme stable
        </b-button>
        </b-row>
      </div>
      <hr v-if="desordreData.id"/>
      <hr v-if="desordreData.id"/>
      <div class="justify-content-center align-items-center text-center" v-if="desordreData.id"><span >Ou</span></div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
  
          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="type"
            rules="required"
          >
            <b-form-group
            v-if="!desordreData.id"
              label="Type"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="desordreData.name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options=types
                :clearable="false"
                input-id="desordre-type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="desordreData.description"
                :state="getValidationState(validationContext)"
                trim
                rows="4"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>



          <validation-provider
            #default="validationContext"
            name="documents"
          >
            <b-form-group
              label="Photos"
              label-for="documents"
            >
              <b-form-file
              placeholder="Ajouter une photo"
              multiple
              v-model="desordreData.documents"
              />      
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>






          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-if="!desordreData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="addDefaut"
            >
              Ajouter
            </b-button>
            <b-button v-if="desordreData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="stableDefaut"
            >
             Ajouter nouveau statut
            </b-button>            
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

          

        </b-form>
      </validation-observer>

<hr v-if="desordreData.id"/>
            <b-form-group
              label="Historique"
              label-for="historique"
              class="ml-2"
              v-if="desordreData.id"
            >
            <app-collapse accordion>
              <app-collapse-item title="ID - 15/02/2020">
                Stable
              </app-collapse-item>
              <app-collapse-item title="ID - 23/06/2018">
                Stable
              </app-collapse-item>
              <app-collapse-item title="ID - 04/01/2016">
                Léger évolution de la fissure - maintenant 3 mm.
              </app-collapse-item>
              <app-collapse-item title="ID - 15/10/2014">
                Stable
              </app-collapse-item>
            </app-collapse>            

            </b-form-group>             
        
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BImg, BFormFile, BBadge, BFormTextarea, BRow, BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import useDesordresList from '@/views/apps/releve/useDesordresList'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BRow,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BImg,
    BFormFile,
    BBadge,
    BCol,
    BFormTextarea,
    AppCollapse,
    AppCollapseItem,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDesordreSidebarActive',
    prop: 'composantsList',
    event: 'update:is-add-new-desordre-sidebar-active',
  },
  props: {
    isAddNewDesordreSidebarActive: {
      type: Boolean,
      required: true,
    },
    inDesordreData: {
      type: Object,
      default: (() => {id:''}),
    },   
    composantsList: {
      type: Array,
      default: []
    }, 
  },

 methods: {
    loadDesordre() {
      if (this.inDesordreData.id != '') {
        this.desordreData = this.inDesordreData
      } else {
        this.desordreData = JSON.parse(JSON.stringify(this.blankDesordreData))
      }
    },
    showDesordre: function (val) { 

      if (val) {
        this.loadDesordre()
      } else {
        this.desordreData = JSON.parse(JSON.stringify(this.blankDesordreData))
      }
    },

    stableDefaut: function() {
      this.desordreData.status = "ok"
      this.$emit('update:is-add-new-desordre-sidebar-active', false)
      this.$emit('stabledesordre', this.desordreData)
    },

    addDefaut: function() {
      this.desordreData.status = "ok"
      this.$emit('update:is-add-new-desordre-sidebar-active', false)
      this.$emit('adddesordre', this.desordreData)
    },

 },  
 
  data() {
    //this.desordreData.value = this.inDesordreData
    this.loadDesordre()
 
    return {
      required,
      alphaNum,
      email,
      types:['Fissure','Epaufrure','Blocage','Rouille des frettes','Fluage','Distorsion','Ecrasement','Décollements'],
      iqoas:['1','2','2E','3','3U'],
    }
  },

created () {
    this.blankDesordreData = {
      id: '',
      name: '',
      description: '',
      image: '',
      status: '',
    }
},

  setup(props, { emit }) {
    const blankDesordreData = {
      id: '',
      name: '',
      description: '',
      image: '',
      status: '',
    }

    let desordreData = ref(JSON.parse(JSON.stringify(blankDesordreData)))

    if (props.inDesordreData.id != '') {
      desordreData.value = JSON.parse(JSON.stringify(blankDesordreData))
    }

    const resetdesordreData = () => {
      desordreData.value = JSON.parse(JSON.stringify(blankDesordreData))
    }

    const onSubmit = () => {
      emit('update:is-add-new-desordre-sidebar-active', false)
      emit('adddesordre', desordreData.value)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetdesordreData)

    const {

      resolveUserStatusVariant,

    } = useDesordresList()

    return {
      desordreData,
      onSubmit,
      blankDesordreData,
      refFormObserver,
      getValidationState,
      resetForm,
      resolveUserStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-desordre-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
